var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "하남시도시재생",
            "src": "/images/sub/sv-regeneration.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "contentsImmersive",
      fn: function () {
        return [_c('client-gnb', {
          attrs: {
            "className": "sub-tab-wrap",
            "tabTitle": "하남시도시재생",
            "tabActive": "신장동 뉴딜사업"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('div', {
    staticClass: "mb-40 mb-md-50"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--dot mb-14 mb-md-20"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("신장동 도시재생뉴딜사업 마스터 플랜")])]), _c('p', {
    staticClass: "page-text font-weight-medium grey-1e--text"
  }, [_vm._v("시행년도 2020 ~ 2023")])]), _c('v-card', [_c('v-img', {
    attrs: {
      "src": "/images/sub/regeneration/newdeal-img.jpg"
    }
  })], 1), _c('div', {
    staticClass: "mt-50 mt-md-70"
  }, [_c('v-row', {
    staticClass: "row-cols-md-5 row-cols-lg-auto",
    attrs: {
      "align-xl": "end",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto",
      "xl": "auto"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "120",
      "src": "/images/sub/regeneration/newdeal-bg.png"
    }
  })], 1), _c('v-spacer', {
    staticClass: "d-none d-xl-block"
  }), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "max-width": "146",
      "src": "/images/sub/regeneration/newdeal-img2.png"
    }
  }), _c('div', {
    staticClass: "mt-8 mt-md-14 text-center"
  }, [_c('div', {
    staticClass: "tit-wrap mb-6 mb-md-8"
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("리모델링")])]), _c('p', {
    staticClass: "page-text page-text--sm"
  }, [_vm._v("나눔 카페, 다목적실"), _c('br'), _vm._v("2층 97.5㎡")])])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "max-width": "146",
      "src": "/images/sub/regeneration/newdeal-img3.png"
    }
  }), _c('div', {
    staticClass: "mt-8 mt-md-14 text-center"
  }, [_c('div', {
    staticClass: "tit-wrap mb-6 mb-md-8"
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("신축")])]), _c('p', {
    staticClass: "page-text page-text--sm"
  }, [_vm._v("시간제 보육실, 장난감 도서관"), _c('br'), _vm._v("3층 161.7㎡")])])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "max-width": "146",
      "src": "/images/sub/regeneration/newdeal-img4.png"
    }
  }), _c('div', {
    staticClass: "mt-8 mt-md-14 text-center"
  }, [_c('div', {
    staticClass: "tit-wrap mb-6 mb-md-8"
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("신축")])]), _c('p', {
    staticClass: "page-text page-text--sm"
  }, [_vm._v("상생협력상가"), _c('br'), _vm._v("3층 165.2㎡")])])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "max-width": "146",
      "src": "/images/sub/regeneration/newdeal-img5.png"
    }
  }), _c('div', {
    staticClass: "mt-8 mt-md-14 text-center"
  }, [_c('div', {
    staticClass: "tit-wrap mb-6 mb-md-8"
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("리모델링")])]), _c('p', {
    staticClass: "page-text page-text--sm"
  }, [_vm._v("경로당 리모델링"), _c('br'), _vm._v("2층 129.06㎡")])])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "max-width": "146",
      "src": "/images/sub/regeneration/newdeal-img6.png"
    }
  }), _c('div', {
    staticClass: "mt-8 mt-md-14 text-center"
  }, [_c('div', {
    staticClass: "tit-wrap mb-6 mb-md-8"
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("신축")])]), _c('p', {
    staticClass: "page-text page-text--sm"
  }, [_vm._v("키즈카페 + 공유세탁소"), _c('br'), _vm._v("지하 1층, 3층 279.7㎡")])])], 1), _c('v-spacer', {
    staticClass: "d-none d-xl-block"
  }), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto",
      "xl": "auto"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "120",
      "src": "/images/sub/regeneration/newdeal-bg2.png"
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }