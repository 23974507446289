var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "주민지원사업",
            "src": "/images/sub/sv-business.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "contentsImmersive",
      fn: function () {
        return [_c('client-gnb', {
          attrs: {
            "className": "sub-tab-wrap",
            "tabTitle": "주민지원사업",
            "tabActive": "주민공모사업"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('v-card', [_c('div', {
    staticClass: "px-20 py-30 pa-md-40 px-lg-80 py-lg-56"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--dot justify-center mb-30 mb-md-36"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("상세내용")])]), _c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center w-md-120px"
  }, [_c('v-divider', {
    staticClass: "h-16px mh-auto min-h-auto align-self-center border-3 primary mr-10",
    attrs: {
      "vertical": ""
    }
  }), _c('strong', {
    staticClass: "font-size-18 font-size-md-20"
  }, [_vm._v("사업취지")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('p', {
    staticClass: "page-text grey-1e--text"
  }, [_vm._v("도시재생뉴딜사업에 대한 하남 시민의 이해와 참여 기회 제공")])])], 1), _c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center w-md-120px"
  }, [_c('v-divider', {
    staticClass: "h-16px mh-auto min-h-auto align-self-center border-3 primary mr-10",
    attrs: {
      "vertical": ""
    }
  }), _c('strong', {
    staticClass: "font-size-18 font-size-md-20"
  }, [_vm._v("사업내용")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('p', {
    staticClass: "page-text grey-1e--text"
  }, [_vm._v("주민이 직접 계획하고 실행하는 사업으로 주체적 마을관리, 일자리 확보, 주민공동체 활동 및 지속가능한 도시재생관련 사업")])])], 1), _c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center w-md-120px"
  }, [_c('v-divider', {
    staticClass: "h-16px mh-auto min-h-auto align-self-center border-3 primary mr-10",
    attrs: {
      "vertical": ""
    }
  }), _c('strong', {
    staticClass: "font-size-18 font-size-md-20"
  }, [_vm._v("참여신청")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('p', {
    staticClass: "page-text grey-1e--text"
  }, [_vm._v("① 시청 고시공고 페이지 참조"), _c('br'), _vm._v("② 신장동 도시재생현장지원센터 방문제출")])])], 1), _c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center w-md-120px"
  }, [_c('v-divider', {
    staticClass: "h-16px mh-auto min-h-auto align-self-center border-3 primary mr-10",
    attrs: {
      "vertical": ""
    }
  }), _c('strong', {
    staticClass: "font-size-18 font-size-md-20"
  }, [_vm._v("문의전화")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('p', {
    staticClass: "page-text grey-1e--text"
  }, [_vm._v("031-790-2176 (신장동 도시재생현장지원센터)")])])], 1)], 1)]), _c('div', {
    staticClass: "v-btn-group--bottom"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-200px w-md-280px",
    attrs: {
      "large": "",
      "block": "",
      "color": "primary",
      "to": "/center/notice/63968144231e4a27d6602507"
    }
  }, [_vm._v("공지사항 참조")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }