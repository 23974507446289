var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "센터소개",
            "src": "/images/sub/sv-intro.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "contentsImmersive",
      fn: function () {
        return [_c('client-gnb', {
          attrs: {
            "className": "sub-tab-wrap",
            "tabTitle": "센터소개",
            "tabActive": "CI/BI"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--first"
  }, [_c('v-card', [_c('div', {
    staticClass: "pa-30 px-lg-50 py-lg-80"
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "contain": "",
      "max-width": "266",
      "src": "/images/logo-vertical.svg"
    }
  })], 1), _c('v-divider'), _c('div', {
    staticClass: "pa-30 py-md-20"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "contain": "",
      "max-width": "296",
      "src": "/images/logo-horizontal.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "contain": "",
      "max-width": "172",
      "src": "/images/logo.svg"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('page-section', [_c('div', {
    staticClass: "tit-wrap tit-wrap--dot"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("COLOR OPTION")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "d-flex flex-wrap align-center justify-center",
    attrs: {
      "tile": "",
      "elevation": "0",
      "color": "#01aee9",
      "height": _vm.$vuetify.breakpoint.xs ? '' : 160
    }
  }, [_c('div', {
    staticClass: "w-100 pa-30 py-md-24"
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "contain": "",
      "max-width": "166",
      "src": "/images/logo-vertical-white.svg"
    }
  })], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "d-flex flex-wrap align-center justify-center",
    attrs: {
      "tile": "",
      "elevation": "0",
      "color": "#01aee9",
      "height": _vm.$vuetify.breakpoint.xs ? '' : 160
    }
  }, [_c('div', {
    staticClass: "w-100 pa-30 py-md-24"
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "contain": "",
      "max-width": "208",
      "src": "/images/logo-white.svg"
    }
  })], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "d-flex flex-wrap align-center justify-center",
    attrs: {
      "tile": "",
      "elevation": "0",
      "color": "#01aee9",
      "height": _vm.$vuetify.breakpoint.xs ? '' : 160
    }
  }, [_c('div', {
    staticClass: "w-100 pa-30 py-md-24"
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "contain": "",
      "max-width": "280",
      "src": "/images/logo-horizontal-white.svg"
    }
  })], 1)])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--small"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--dot"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("MONOCOLOR OPTION")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "d-flex flex-wrap align-center justify-center",
    attrs: {
      "tile": "",
      "outlined": "",
      "elevation": "0",
      "height": _vm.$vuetify.breakpoint.xs ? '' : 160
    }
  }, [_c('div', {
    staticClass: "w-100 pa-30 py-md-24"
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "contain": "",
      "max-width": "166",
      "src": "/images/logo-vertical-dark.svg"
    }
  })], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "d-flex flex-wrap align-center justify-center",
    attrs: {
      "tile": "",
      "outlined": "",
      "elevation": "0",
      "height": _vm.$vuetify.breakpoint.xs ? '' : 160
    }
  }, [_c('div', {
    staticClass: "w-100 pa-30 py-md-24"
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "contain": "",
      "max-width": "208",
      "src": "/images/logo-dark.svg"
    }
  })], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "d-flex flex-wrap align-center justify-center",
    attrs: {
      "tile": "",
      "outlined": "",
      "elevation": "0",
      "height": _vm.$vuetify.breakpoint.xs ? '' : 160
    }
  }, [_c('div', {
    staticClass: "w-100 pa-30 py-md-24"
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "contain": "",
      "max-width": "280",
      "src": "/images/logo-horizontal-dark.svg"
    }
  })], 1)])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--last"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--dot"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("센터의 캐릭터")])]), _c('v-card', {
    staticClass: "d-flex flex-wrap align-center justify-center",
    attrs: {
      "tile": "",
      "outlined": "",
      "elevation": "0",
      "height": _vm.$vuetify.breakpoint.xs ? '' : 260
    }
  }, [_c('div', {
    staticClass: "w-100 pa-30 py-md-24 py-lg-60"
  }, [_c('v-row', {
    staticClass: "ma-n20 ma-md-n40 ma-lg-n90",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-20 pa-md-40 pa-lg-90",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "contain": "",
      "max-width": "162",
      "src": "/images/character.svg"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-20 pa-md-40 pa-lg-90",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "contain": "",
      "max-width": "346",
      "src": "/images/character-horizontal.svg"
    }
  })], 1)], 1)], 1)]), _c('v-card', {
    staticClass: "d-flex flex-wrap align-center justify-center",
    attrs: {
      "tile": "",
      "elevation": "0",
      "color": "#01aee9",
      "height": _vm.$vuetify.breakpoint.xs ? '' : 260
    }
  }, [_c('div', {
    staticClass: "w-100 pa-30 py-md-24 py-lg-60"
  }, [_c('v-row', {
    staticClass: "ma-n20 ma-md-n40 ma-lg-n90",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-20 pa-md-40 pa-lg-90",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "contain": "",
      "max-width": "162",
      "src": "/images/character-white.svg"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-20 pa-md-40 pa-lg-90",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "contain": "",
      "max-width": "346",
      "src": "/images/character-horizontal-white.svg"
    }
  })], 1)], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }