var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "주민지원사업",
            "src": "/images/sub/sv-business.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "contentsImmersive",
      fn: function () {
        return [_c('client-gnb', {
          attrs: {
            "className": "sub-tab-wrap",
            "tabTitle": "주민지원사업",
            "tabActive": "노후주택 개보수지원사업"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('v-card', [_c('div', {
    staticClass: "px-20 py-30 pa-md-40 px-lg-80 py-lg-56"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--dot justify-center mb-30 mb-md-36"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("상세내용")])]), _c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center w-md-120px"
  }, [_c('v-divider', {
    staticClass: "h-16px mh-auto min-h-auto align-self-center border-3 primary mr-10",
    attrs: {
      "vertical": ""
    }
  }), _c('strong', {
    staticClass: "font-size-18 font-size-md-20"
  }, [_vm._v("사업취지")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('p', {
    staticClass: "page-text grey-1e--text"
  }, [_vm._v("노후주택의 정주환경을 개선하기 위한 경기주택도시공사(GH)의 지원사업")])])], 1), _c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center w-md-120px"
  }, [_c('v-divider', {
    staticClass: "h-16px mh-auto min-h-auto align-self-center border-3 primary mr-10",
    attrs: {
      "vertical": ""
    }
  }), _c('strong', {
    staticClass: "font-size-18 font-size-md-20"
  }, [_vm._v("사업내용")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('p', {
    staticClass: "page-text grey-1e--text"
  }, [_vm._v(" 노후주택 개보수 지원 사업은 주거 취약계층 등을 대상으로 창호·단열·난방·지붕 공사 등의 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 노후주택 개보수 비용을 한 가구당 최대 1000만원까지 지원 ")])])], 1), _c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center w-md-120px"
  }, [_c('v-divider', {
    staticClass: "h-16px mh-auto min-h-auto align-self-center border-3 primary mr-10",
    attrs: {
      "vertical": ""
    }
  }), _c('strong', {
    staticClass: "font-size-18 font-size-md-20"
  }, [_vm._v("참여신청")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('p', {
    staticClass: "page-text grey-1e--text"
  }, [_vm._v(" 하남시청 도시재생과 도시재생사업팀 방문제출 ")])])], 1), _c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center w-md-120px"
  }, [_c('v-divider', {
    staticClass: "h-16px mh-auto min-h-auto align-self-center border-3 primary mr-10",
    attrs: {
      "vertical": ""
    }
  }), _c('strong', {
    staticClass: "font-size-18 font-size-md-20"
  }, [_vm._v("문의전화")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('p', {
    staticClass: "page-text grey-1e--text"
  }, [_vm._v("031-790-5945 (하남시청 도시재생과 도시재생사업팀)")])])], 1)], 1)]), _c('div', {
    staticClass: "v-btn-group--bottom"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-200px w-md-280px",
    attrs: {
      "large": "",
      "block": "",
      "color": "primary",
      "to": "/center/notice/639681dc231e4a27d660251b"
    }
  }, [_vm._v("공지사항 참조")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }