<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="센터소개" src="/images/sub/sv-intro.jpg"></sub-visual>
        </template>
        <template v-slot:contentsImmersive>
            <client-gnb className="sub-tab-wrap" tabTitle="센터소개" tabActive="오시는길"></client-gnb>
        </template>

        <page-section class="page-section--first">
            <v-card elevation="0">
                <v-responsive :aspect-ratio="1200/460">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.6768857638326!2d127.21241415106691!3d37.53911333335647!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357cb229714db195%3A0xc3500220f40bf28b!2z6rK96riw64-EIO2VmOuCqOyLnCDrjIDssq3roZwgMTA!5e0!3m2!1sko!2skr!4v1665048932652!5m2!1sko!2skr" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </v-responsive>
                <v-card class="mt-n10 mt-md-n12">
                    <div class="pa-30 pa-md-40 px-lg-60 py-lg-46">
                        <v-row>
                            <v-col cols="12" md="4">
                                <div class="tit primary--text">기초센터 잇다</div>
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-row align="center" class="mx-n8 my-n4">
                                    <v-col cols="12" class="px-8 py-4">
                                        <p class="page-text grey-1e--text"><strong class="pr-8 pr-md-10">도시전략과</strong>경기도 하남시 대청로 10 (하남시청 별관 2층)</p>
                                    </v-col>
                                    <v-col cols="12" md="auto" class="px-8 py-4">
                                        <p class="page-text grey-1e--text"><strong class="pr-8 pr-md-10">전화번호</strong>031-790-6353</p>
                                    </v-col>
                                    <v-col cols="auto" class="d-none d-md-flex align-center px-8 py-4">
                                        <v-divider vertical class="h-10px" />
                                    </v-col>
                                    <v-col cols="12" md="auto" class="px-8 py-4">
                                        <p class="page-text grey-1e--text"><strong class="pr-8 pr-md-10">운영시간</strong>월~금 09:00~18:00</p>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </v-card>
        </page-section>

        <page-section class="pt-40 pt-md-60">
            <v-card elevation="0">
                <v-responsive :aspect-ratio="1200/460">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.731189963381!2d127.2085633510667!3d37.53783343342967!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357cb187ed2126d7%3A0xa0e5075a2e42f1e5!2z6rK96riw64-EIO2VmOuCqOyLnCDsi6DsnqUx66GcMjLrsojquLggMTUtMQ!5e0!3m2!1sko!2skr!4v1665049040274!5m2!1sko!2skr" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </v-responsive>
                <v-card class="mt-n10 mt-md-n12">
                    <div class="pa-30 pa-md-40 px-lg-60 py-lg-46">
                        <v-row>
                            <v-col cols="12" md="4">
                                <div class="tit primary--text">현장지원센터 날다</div>
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-row align="center" class="mx-n8 my-n4">
                                    <v-col cols="12" class="px-8 py-4">
                                        <p class="page-text grey-1e--text"><strong class="pr-8 pr-md-10">신장동 도시재생현장지원센터</strong>경기 하남시 신장1로22번길 15-1</p>
                                    </v-col>
                                    <v-col cols="12" md="auto" class="px-8 py-4">
                                        <p class="page-text grey-1e--text"><strong class="pr-8 pr-md-10">전화번호</strong>031-796-2176</p>
                                    </v-col>
                                    <v-col cols="auto" class="d-none d-md-flex align-center px-8 py-4">
                                        <v-divider vertical class="h-10px" />
                                    </v-col>
                                    <v-col cols="12" md="auto" class="px-8 py-4">
                                        <p class="page-text grey-1e--text"><strong class="pr-8 pr-md-10">운영시간</strong>월~금 09:00~18:00</p>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </v-card>
        </page-section>

        <page-section class="pt-40 pt-md-60 page-section--last">
            <v-card elevation="0">
                <v-responsive :aspect-ratio="1200/460">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.6527137563817!2d127.21176325106693!3d37.53968303332381!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357cb22983666189%3A0x76a32837b057ae95!2z6rK96riw64-EIO2VmOuCqOyLnCDsi6DsnqXrj5kgNTIwLTE!5e0!3m2!1sko!2skr!4v1665049198217!5m2!1sko!2skr" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </v-responsive>
                <v-card class="mt-n10 mt-md-n12">
                    <div class="pa-30 pa-md-40 px-lg-60 py-lg-46">
                        <v-row>
                            <v-col cols="12" md="4">
                                <div class="tit primary--text">생활문화센터 하다</div>
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-row align="center" class="mx-n8 my-n4">
                                    <v-col cols="12" class="px-8 py-4">
                                        <p class="page-text grey-1e--text"><strong class="pr-8 pr-md-10">생활문화센터</strong>경기도 하남시 대청로 8 (시청옆 경관광장)</p>
                                    </v-col>
                                    <v-col cols="12" md="auto" class="px-8 py-4">
                                        <p class="page-text grey-1e--text"><strong class="pr-8 pr-md-10">전화번호</strong>031-791-5002</p>
                                    </v-col>
                                    <v-col cols="auto" class="d-none d-md-flex align-center px-8 py-4">
                                        <v-divider vertical class="h-10px" />
                                    </v-col>
                                    <v-col cols="12" md="auto" class="px-8 py-4">
                                        <p class="page-text grey-1e--text"><strong class="pr-8 pr-md-10">운영시간</strong>수~일 10:00~19:00</p>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </v-card>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import ClientGnb from "@/components/client/inc/client-gnb.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/dumb/page-section.vue";

export default {
    components: {
        ClientPage,
        ClientGnb,
        SubVisual,
        PageSection,
    },
    mounted() {
    },
    methods: {
    },
};
</script>

<style scoped>
</style>