<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="하남시도시재생" src="/images/sub/sv-regeneration.jpg"></sub-visual>
        </template>
        <template v-slot:contentsImmersive>
            <client-gnb className="sub-tab-wrap" tabTitle="하남시도시재생" tabActive="사업구성"></client-gnb>
        </template>

        <page-section class="page-section--first page-section--last">
            <div>
                <v-card>
                    <div class="pa-30 py-md-50 px-lg-50 px-xl-90">
                        <v-row no-gutters align="center">
                            <v-col cols="12" md="auto">
                                <div class="w-md-300px w-lg-440px mb-20 mb-md-0 pr-md-24">
                                    <v-img contain max-height="170" :position="$vuetify.breakpoint.xs ? 'center' : 'left center'" src="/images/sub/regeneration/structure-img.png" />
                                </div>
                            </v-col>
                            <v-col cols="12" md="">
                                <div class="mb-16 mb-md-22">
                                    <v-img contain position="left center" max-height="40" src="/images/sub/regeneration/structure-text.png" alt="기초센터 잇다" />
                                </div>
                                <p class="page-text">도시재생사업 전반의 컨트롤타워 <br class="d-none d-md-block"/>역할수행과 도시재생리더 육성 및 민간 거버넌스 구축</p>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </div>

            <div class="mt-28 mt-md-34">
                <v-card>
                    <div class="pa-30 py-md-50 px-lg-50 px-xl-90">
                        <v-row no-gutters align="center">
                            <v-col cols="12" md="auto">
                                <div class="w-md-300px w-lg-440px mb-20 mb-md-0 pr-md-24">
                                    <v-img contain max-height="170" :position="$vuetify.breakpoint.xs ? 'center' : 'left center'" src="/images/sub/regeneration/structure-img2.png" />
                                </div>
                            </v-col>
                            <v-col cols="12" md="">
                                <div class="mb-16 mb-md-22">
                                    <v-img contain position="left center" max-height="40" src="/images/sub/regeneration/structure-text2.png" alt="현장지원센터 날다" />
                                </div>
                                <p class="page-text">주민공동체 발굴과 역량강화를 통한 <br class="d-none d-md-block"/>도시재생 인식과 이해도를 높이고 주민 자립 지원</p>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </div>

            <div class="mt-28 mt-md-34">
                <v-card>
                    <div class="pa-30 py-md-50 px-lg-50 px-xl-90">
                        <v-row no-gutters align="center">
                            <v-col cols="12" md="auto">
                                <div class="w-md-300px w-lg-440px mb-20 mb-md-0 pr-md-24">
                                    <v-img contain max-height="170" :position="$vuetify.breakpoint.xs ? 'center' : 'left center'" src="/images/sub/regeneration/structure-img3.png" />
                                </div>
                            </v-col>
                            <v-col cols="12" md="">
                                <div class="mb-16 mb-md-22">
                                    <v-img contain position="left center" max-height="40" src="/images/sub/regeneration/structure-text3.png" alt="생활문화센터 하다" />
                                </div>
                                <p class="page-text">지역주민의 문화예술활동을 지역사회와 공유하며 <br class="d-none d-md-block"/>생활문화를 만들어가는 커뮤니티 공간</p>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import ClientGnb from "@/components/client/inc/client-gnb.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/dumb/page-section.vue";

export default {
    components: {
        ClientPage,
        ClientGnb,
        SubVisual,
        PageSection,
    },
    mounted() {
    },
    methods: {
    },
};
</script>

<style scoped>
</style>