<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="하남시도시재생" src="/images/sub/sv-regeneration.jpg"></sub-visual>
        </template>
        <template v-slot:contentsImmersive>
            <client-gnb className="sub-tab-wrap" tabTitle="하남시도시재생" tabActive="신장동 뉴딜사업"></client-gnb>
        </template>

        <page-section class="page-section--first page-section--last">
            <div class="mb-40 mb-md-50">
                <div class="tit-wrap tit-wrap--dot mb-14 mb-md-20">
                    <h2 class="tit">신장동 도시재생뉴딜사업 마스터 플랜</h2>
                </div>
                <p class="page-text font-weight-medium grey-1e--text">시행년도 2020 ~ 2023</p>
            </div>

            <v-card>
                <v-img src="/images/sub/regeneration/newdeal-img.jpg" />
            </v-card>

            <div class="mt-50 mt-md-70">
                <v-row align-xl="end" justify="center" class="row-cols-md-5 row-cols-lg-auto">
                    <v-col cols="auto" xl="auto" class="d-none d-xl-block"><v-img max-width="120" src="/images/sub/regeneration/newdeal-bg.png" /></v-col>
                    <v-spacer class="d-none d-xl-block"/>
                    <v-col cols="6" sm="4">
                        <v-img max-width="146" class="ma-auto" src="/images/sub/regeneration/newdeal-img2.png" />
                        <div class="mt-8 mt-md-14 text-center">
                            <div class="tit-wrap mb-6 mb-md-8"><h3 class="tit tit--sm">리모델링</h3></div>
                            <p class="page-text page-text--sm">나눔 카페, 다목적실<br/>2층 97.5㎡</p>
                        </div>
                    </v-col>
                    <v-col cols="6" sm="4">
                        <v-img max-width="146" class="ma-auto" src="/images/sub/regeneration/newdeal-img3.png" />
                        <div class="mt-8 mt-md-14 text-center">
                            <div class="tit-wrap mb-6 mb-md-8"><h3 class="tit tit--sm">신축</h3></div>
                            <p class="page-text page-text--sm">시간제 보육실, 장난감 도서관<br/>3층 161.7㎡</p>
                        </div>
                    </v-col>
                    <v-col cols="6" sm="4">
                        <v-img max-width="146" class="ma-auto" src="/images/sub/regeneration/newdeal-img4.png" />
                        <div class="mt-8 mt-md-14 text-center">
                            <div class="tit-wrap mb-6 mb-md-8"><h3 class="tit tit--sm">신축</h3></div>
                            <p class="page-text page-text--sm">상생협력상가<br/>3층 165.2㎡</p>
                        </div>
                    </v-col>
                    <v-col cols="6" sm="4">
                        <v-img max-width="146" class="ma-auto" src="/images/sub/regeneration/newdeal-img5.png" />
                        <div class="mt-8 mt-md-14 text-center">
                            <div class="tit-wrap mb-6 mb-md-8"><h3 class="tit tit--sm">리모델링</h3></div>
                            <p class="page-text page-text--sm">경로당 리모델링<br/>2층 129.06㎡</p>
                        </div>
                    </v-col>
                    <v-col cols="6" sm="4">
                        <v-img max-width="146" class="ma-auto" src="/images/sub/regeneration/newdeal-img6.png" />
                        <div class="mt-8 mt-md-14 text-center">
                            <div class="tit-wrap mb-6 mb-md-8"><h3 class="tit tit--sm">신축</h3></div>
                            <p class="page-text page-text--sm">키즈카페 + 공유세탁소<br/>지하 1층, 3층 279.7㎡</p>
                        </div>
                    </v-col>
                    <v-spacer class="d-none d-xl-block"/>
                    <v-col cols="auto" xl="auto" class="d-none d-xl-block"><v-img max-width="120" src="/images/sub/regeneration/newdeal-bg2.png" /></v-col>
                </v-row>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import ClientGnb from "@/components/client/inc/client-gnb.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/dumb/page-section.vue";

export default {
    components: {
        ClientPage,
        ClientGnb,
        SubVisual,
        PageSection,
    },
    mounted() {
    },
    methods: {
    },
};
</script>

<style scoped>
</style>