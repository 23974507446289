var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "센터소개",
            "src": "/images/sub/sv-intro.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "contentsImmersive",
      fn: function () {
        return [_c('client-gnb', {
          attrs: {
            "className": "sub-tab-wrap",
            "tabTitle": "센터소개",
            "tabActive": "센터소개"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--first"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('div', {
    staticClass: "img-wrap img-wrap--left-bg"
  }, [_c('v-img', {
    attrs: {
      "max-width": "540",
      "src": "/images/sub/intro/center-img.png"
    }
  })], 1)]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "mb-12 mt-md-20"
  }, [_c('v-img', {
    attrs: {
      "max-width": "50",
      "src": "/images/character.svg"
    }
  })], 1), _c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v("하남시 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("도시재생지원센터")]), _c('v-divider', {
    staticClass: "my-20 my-md-30"
  }), _c('p', {
    staticClass: "page-text grey-1e--text"
  }, [_vm._v("도시재생현장지원센터는 사업지 내 도시재생사업의 성공적인 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("추진과 확산을 위한 중간지원조직으로서 행정 - 전문가 - 주민 등 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("참여주체 간의 역할과 상호 연계하고, 조정하는 역할을 합니다.")])], 1)], 1)], 1), _c('page-section', [_c('div', {
    staticClass: "tit-wrap tit-wrap--dot"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("센터의 구성")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "max-height": "132",
      "src": "/images/sub/intro/center-img2.png"
    }
  }), _c('v-divider', {
    staticClass: "border-2 primary"
  }), _c('v-card', {
    attrs: {
      "min-height": _vm.$vuetify.breakpoint.xs ? '' : 288,
      "rounded": "t-0"
    }
  }, [_c('div', {
    staticClass: "pa-30 px-lg-50 py-lg-60"
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "position": "left center",
      "max-height": "40",
      "src": "/images/sub/intro/center-text.png",
      "alt": "기초센터 잇다"
    }
  }), _c('p', {
    staticClass: "page-text mt-14 mt-md-24"
  }, [_vm._v(" 도시재생사업 전반의 컨트롤타워 역할"), _c('br'), _vm._v(" 도시재생리더 육성"), _c('br'), _vm._v(" 민간 거버넌스 구축 ")])], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "max-height": "132",
      "src": "/images/sub/intro/center-img3.png"
    }
  }), _c('v-divider', {
    staticClass: "border-2 primary"
  }), _c('v-card', {
    attrs: {
      "min-height": _vm.$vuetify.breakpoint.xs ? '' : 288,
      "rounded": "t-0"
    }
  }, [_c('div', {
    staticClass: "pa-30 px-lg-50 py-lg-60"
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "position": "left center",
      "max-height": "40",
      "src": "/images/sub/intro/center-text2.png",
      "alt": "현장지원센터 날다"
    }
  }), _c('p', {
    staticClass: "page-text mt-14 mt-md-24"
  }, [_vm._v(" 주민 활동 지원"), _c('br'), _vm._v(" 주민 역량 강화"), _c('br'), _vm._v(" 신장동 네트워킹 활성화 ")])], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "max-height": "132",
      "src": "/images/sub/intro/center-img4.png"
    }
  }), _c('v-divider', {
    staticClass: "border-2 primary"
  }), _c('v-card', {
    attrs: {
      "min-height": _vm.$vuetify.breakpoint.xs ? '' : 288,
      "rounded": "t-0"
    }
  }, [_c('div', {
    staticClass: "pa-30 px-lg-50 py-lg-60"
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "position": "left center",
      "max-height": "40",
      "src": "/images/sub/intro/center-text3.png",
      "alt": "생활문화센터 하다"
    }
  }), _c('p', {
    staticClass: "page-text mt-14 mt-md-24"
  }, [_vm._v(" 주민의 문화감수성 향상"), _c('br'), _vm._v(" 주민의 문화적 역량 증진"), _c('br'), _vm._v(" 동아리 활동 지원"), _c('br'), _vm._v(" 지역사회 네트워킹 역할 ")])], 1)])], 1)], 1)], 1), _c('page-section', [_c('div', {
    staticClass: "tit-wrap tit-wrap--dot"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("센터의 역할")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.xs ? 26 : 32,
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "width": "100%",
      "height": "100%",
      "rounded": "circle",
      "color": "primary"
    }
  }, [_c('strong', {
    staticClass: "font-size-16 font-size-md-20 white--text"
  }, [_vm._v("1")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_c('p', {
    staticClass: "page-text grey-1e--text"
  }, [_vm._v("도시재생 전략계획, "), _c('b', [_vm._v("도시재생 활성화 계획 수립 지원")])])])], 1)], 1), _c('div', {
    staticClass: "mt-2 mt-md-8"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.xs ? 26 : 32,
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "width": "100%",
      "height": "100%",
      "rounded": "circle",
      "color": "primary"
    }
  }, [_c('strong', {
    staticClass: "font-size-16 font-size-md-20 white--text"
  }, [_vm._v("2")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_c('p', {
    staticClass: "page-text grey-1e--text"
  }, [_vm._v("도시재생사업 "), _c('b', [_vm._v("발굴 및 활성화 지원")])])])], 1)], 1), _c('div', {
    staticClass: "mt-2 mt-md-8"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.xs ? 26 : 32,
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "width": "100%",
      "height": "100%",
      "rounded": "circle",
      "color": "primary"
    }
  }, [_c('strong', {
    staticClass: "font-size-16 font-size-md-20 white--text"
  }, [_vm._v("3")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_c('p', {
    staticClass: "page-text grey-1e--text"
  }, [_vm._v("주민중심의 "), _c('b', [_vm._v("민관 협력추진체계 구축")])])])], 1)], 1), _c('div', {
    staticClass: "mt-2 mt-md-8"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.xs ? 26 : 32,
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "width": "100%",
      "height": "100%",
      "rounded": "circle",
      "color": "primary"
    }
  }, [_c('strong', {
    staticClass: "font-size-16 font-size-md-20 white--text"
  }, [_vm._v("4")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_c('p', {
    staticClass: "page-text grey-1e--text"
  }, [_vm._v("문화적 재생, 사회적 경제 등 "), _c('b', [_vm._v("연계사업 추진")])])])], 1)], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "568",
      "src": "/images/sub/intro/center-img5.png"
    }
  })], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--small page-section--last"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": "3",
      "lg": "2"
    }
  }, [_c('v-responsive', {
    staticClass: "ma-auto",
    attrs: {
      "width": _vm.$vuetify.breakpoint.xs ? 100 : 120,
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "width": "100%",
      "height": "100%",
      "rounded": "circle",
      "color": "primary"
    }
  }, [_c('v-img', {
    staticClass: "ml-4",
    attrs: {
      "contain": "",
      "max-height": "48",
      "src": "/images/sub/intro/center-icon.png"
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-8 mt-md-14 text-center"
  }, [_c('div', {
    staticClass: "tit-wrap mb-6 mb-md-8"
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("조사")])]), _c('p', {
    staticClass: "page-text page-text--sm"
  }, [_vm._v("도시재생사업 추진을 위한 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("자원조사 및 주민의견 수렴")])])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": "3",
      "lg": "2"
    }
  }, [_c('v-responsive', {
    staticClass: "ma-auto",
    attrs: {
      "width": _vm.$vuetify.breakpoint.xs ? 100 : 120,
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "width": "100%",
      "height": "100%",
      "rounded": "circle",
      "color": "primary"
    }
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "max-height": "48",
      "src": "/images/sub/intro/center-icon2.png"
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-8 mt-md-14 text-center"
  }, [_c('div', {
    staticClass: "tit-wrap mb-6 mb-md-8"
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("역량강화")])]), _c('p', {
    staticClass: "page-text page-text--sm"
  }, [_vm._v("주민역량강화를 위한 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("프로그램 운영")])])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": "3",
      "lg": "2"
    }
  }, [_c('v-responsive', {
    staticClass: "ma-auto",
    attrs: {
      "width": _vm.$vuetify.breakpoint.xs ? 100 : 120,
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "width": "100%",
      "height": "100%",
      "rounded": "circle",
      "color": "primary"
    }
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "max-height": "48",
      "src": "/images/sub/intro/center-icon3.png"
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-8 mt-md-14 text-center"
  }, [_c('div', {
    staticClass: "tit-wrap mb-6 mb-md-8"
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("주민조직 구축")])]), _c('p', {
    staticClass: "page-text page-text--sm"
  }, [_vm._v("주민조직 구축을 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("위한 지원")])])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": "3",
      "lg": "2"
    }
  }, [_c('v-responsive', {
    staticClass: "ma-auto",
    attrs: {
      "width": _vm.$vuetify.breakpoint.xs ? 100 : 120,
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "width": "100%",
      "height": "100%",
      "rounded": "circle",
      "color": "primary"
    }
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "max-height": "48",
      "src": "/images/sub/intro/center-icon4.png"
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-8 mt-md-14 text-center"
  }, [_c('div', {
    staticClass: "tit-wrap mb-6 mb-md-8"
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("홍보")])]), _c('p', {
    staticClass: "page-text page-text--sm"
  }, [_vm._v("도시재생사업 홍보 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("소식지 및 SNS 운영")])])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": "3",
      "lg": "2"
    }
  }, [_c('v-responsive', {
    staticClass: "ma-auto",
    attrs: {
      "width": _vm.$vuetify.breakpoint.xs ? 100 : 120,
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "width": "100%",
      "height": "100%",
      "rounded": "circle",
      "color": "primary"
    }
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "max-height": "48",
      "src": "/images/sub/intro/center-icon5.png"
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-8 mt-md-14 text-center"
  }, [_c('div', {
    staticClass: "tit-wrap mb-6 mb-md-8"
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("거점공간")])]), _c('p', {
    staticClass: "page-text page-text--sm"
  }, [_vm._v("거점공간 운영 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("및 지원")])])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": "3",
      "lg": "2"
    }
  }, [_c('v-responsive', {
    staticClass: "ma-auto",
    attrs: {
      "width": _vm.$vuetify.breakpoint.xs ? 100 : 120,
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "width": "100%",
      "height": "100%",
      "rounded": "circle",
      "color": "primary"
    }
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "max-height": "48",
      "src": "/images/sub/intro/center-icon6.png"
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-8 mt-md-14 text-center"
  }, [_c('div', {
    staticClass: "tit-wrap mb-6 mb-md-8"
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("기획")])]), _c('p', {
    staticClass: "page-text page-text--sm"
  }, [_vm._v("소규모 프로젝트 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("기획 및 운영")])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }