<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="하남시도시재생" src="/images/sub/sv-regeneration.jpg"></sub-visual>
        </template>
        <template v-slot:contentsImmersive>
            <client-gnb className="sub-tab-wrap" tabTitle="하남시도시재생" tabActive="도시재생이란?"></client-gnb>
        </template>

        <page-section class="page-section--first">
            <v-row>
                <v-col cols="12" sm="6">
                    <div class="img-wrap img-wrap--left-bg">
                        <v-img max-width="436" src="/images/sub/regeneration/what-img.png" />
                    </div>
                </v-col>
                <v-spacer />
                <v-col cols="12" sm="6" md="5">
                    <div class="mb-12 mt-md-20">
                        <v-img max-width="50" src="/images/character.svg" />
                    </div>
                    <h2 class="tit tit--lg">도시재생이란?</h2>
                    <v-divider class="my-20 my-md-30" />
                    <p class="page-text grey-1e--text">인구 감소, 산업 쇠퇴, 주거환경 노후로 인해 <br class="d-none d-md-block"/>쇠락하는 도시를 물리적 · 문화적 · 사회적 · 경제적으로 <br class="d-none d-md-block"/>개선하여 도시의 기능을 회복하고 경쟁력 있는 <br class="d-none d-md-block"/>정주환경으로 재창조하는 사업입니다.</p>
                </v-col>
            </v-row>
        </page-section>

        <page-section class="page-section--last">
            <div class="tit-wrap tit-wrap--dot">
                <h2 class="tit">도시재생사업에 참여하기</h2>
            </div>
            <v-row justify="center">
                <v-col cols="12" sm="6" md="4">
                    <v-card height="100%">
                        <div class="pa-30 pa-md-40 py-lg-50">
                            <v-img contain max-height="74" src="/images/sub/regeneration/what-icon.png" />
                            <div class="mt-10 mt-md-18 text-center">
                                <div class="tit-wrap mb-6 mb-md-8"><h3 class="tit tit--sm primary--text">교육 및 워크숍</h3></div>
                                <p class="page-text">도시재생 마을학교 등 관심사에 따른 프로그램 참여</p>
                            </div>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-card height="100%">
                        <div class="pa-30 pa-md-40 py-lg-50">
                            <v-img contain max-height="64" src="/images/sub/regeneration/what-icon2.png" />
                            <div class="mt-10 mt-md-18 text-center">
                                <div class="tit-wrap mb-6 mb-md-8"><h3 class="tit tit--sm primary--text">SNS 및 소식지</h3></div>
                                <p class="page-text">SNS를 통한 도시재생 소식 공유</p>
                            </div>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-card height="100%">
                        <div class="pa-30 pa-md-40 py-lg-50">
                            <v-img contain max-height="64" src="/images/sub/regeneration/what-icon3.png" />
                            <div class="mt-10 mt-md-18 text-center">
                                <div class="tit-wrap mb-6 mb-md-8"><h3 class="tit tit--sm primary--text">마을사업단</h3></div>
                                <p class="page-text">도시재생 마중물사업 참여</p>
                            </div>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-card height="100%">
                        <div class="pa-30 pa-md-40 py-lg-50">
                            <v-img contain max-height="64" src="/images/sub/regeneration/what-icon4.png" />
                            <div class="mt-10 mt-md-18 text-center">
                                <div class="tit-wrap mb-6 mb-md-8"><h3 class="tit tit--sm primary--text">주민협의체</h3></div>
                                <p class="page-text">· 도시재생 주체로서 관련의견 제시<br/>· 지속가능한 마을공동체활동 참여</p>
                            </div>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-card height="100%">
                        <div class="pa-30 pa-md-40 py-lg-50">
                            <v-img contain max-height="64" src="/images/sub/regeneration/what-icon5.png" />
                            <div class="mt-10 mt-md-18 text-center">
                                <div class="tit-wrap mb-6 mb-md-8"><h3 class="tit tit--sm primary--text">주민공모사업</h3></div>
                                <p class="page-text"> 도시재생과 지역특성에 맞는 사업을 직접 기획·실행</p>
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import ClientGnb from "@/components/client/inc/client-gnb.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/dumb/page-section.vue";

export default {
    components: {
        ClientPage,
        ClientGnb,
        SubVisual,
        PageSection,
    },
    mounted() {
    },
    methods: {
    },
};
</script>

<style scoped>
</style>