<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="주민지원사업" src="/images/sub/sv-business.jpg"></sub-visual>
        </template>
        <template v-slot:contentsImmersive>
            <client-gnb className="sub-tab-wrap" tabTitle="주민지원사업" tabActive="노후주택 개보수지원사업"></client-gnb>
            <!-- <v-tabs hide-slider show-arrows>
                <v-tab to="/business/resident">
                    주민공모사업
                </v-tab>
                <v-tab to="/business/green">
                    녹색건축물 조성지원사업
                </v-tab>
                <v-tab to="/business/house">
                    노후주택 개보수지원사업
                </v-tab>
            </v-tabs> -->
        </template>

        <page-section class="page-section--first page-section--last">
            <v-card>
                <div class="px-20 py-30 pa-md-40 px-lg-80 py-lg-56">
                    <div class="tit-wrap tit-wrap--dot justify-center mb-30 mb-md-36">
                        <h2 class="tit">상세내용</h2>
                    </div>
                    <v-row class="row--x-small">
                        <v-col cols="12" md="auto">
                            <div class="d-flex align-center w-md-120px">
                                <v-divider vertical class="h-16px mh-auto min-h-auto align-self-center border-3 primary mr-10" />
                                <strong class="font-size-18 font-size-md-20">사업취지</strong>
                            </div>
                        </v-col>
                        <v-col cols="12" md="">
                            <p class="page-text grey-1e--text">노후주택의 정주환경을 개선하기 위한 경기주택도시공사(GH)의 지원사업</p>
                        </v-col>
                    </v-row>
                    <v-row class="row--x-small">
                        <v-col cols="12" md="auto">
                            <div class="d-flex align-center w-md-120px">
                                <v-divider vertical class="h-16px mh-auto min-h-auto align-self-center border-3 primary mr-10" />
                                <strong class="font-size-18 font-size-md-20">사업내용</strong>
                            </div>
                        </v-col>
                        <v-col cols="12" md="">
                            <p class="page-text grey-1e--text">
                                노후주택 개보수 지원 사업은 주거 취약계층 등을 대상으로 창호·단열·난방·지붕 공사 등의 <br class="d-none d-md-block" />
                                노후주택 개보수 비용을 한 가구당 최대 1000만원까지 지원
                            </p>
                        </v-col>
                    </v-row>
                    <v-row class="row--x-small">
                        <v-col cols="12" md="auto">
                            <div class="d-flex align-center w-md-120px">
                                <v-divider vertical class="h-16px mh-auto min-h-auto align-self-center border-3 primary mr-10" />
                                <strong class="font-size-18 font-size-md-20">참여신청</strong>
                            </div>
                        </v-col>
                        <v-col cols="12" md="">
                            <p class="page-text grey-1e--text">
                                하남시청 도시재생과 도시재생사업팀 방문제출
                            </p>
                        </v-col>
                    </v-row>
                    <v-row class="row--x-small">
                        <v-col cols="12" md="auto">
                            <div class="d-flex align-center w-md-120px">
                                <v-divider vertical class="h-16px mh-auto min-h-auto align-self-center border-3 primary mr-10" />
                                <strong class="font-size-18 font-size-md-20">문의전화</strong>
                            </div>
                        </v-col>
                        <v-col cols="12" md="">
                            <p class="page-text grey-1e--text">031-790-5945 (하남시청 도시재생과 도시재생사업팀)</p>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <div class="v-btn-group--bottom">
                <v-row justify="center" class="row--small">
                    <v-col cols="auto">
                        <v-btn large block color="primary" to="/center/notice/639681dc231e4a27d660251b" class="w-200px w-md-280px">공지사항 참조</v-btn>
                    </v-col>
                </v-row>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import ClientGnb from "@/components/client/inc/client-gnb.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/dumb/page-section.vue";

export default {
    components: {
        ClientPage,
        ClientGnb,
        SubVisual,
        PageSection,
    },
    mounted() {},
    methods: {},
};
</script>

<style scoped></style>
