<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="센터소개" src="/images/sub/sv-intro.jpg"></sub-visual>
        </template>
        <template v-slot:contentsImmersive>
            <client-gnb className="sub-tab-wrap" tabTitle="센터소개" tabActive="CI/BI"></client-gnb>
        </template>

        <page-section class="page-section--first">
            <v-card>
                <div class="pa-30 px-lg-50 py-lg-80">
                    <v-img contain max-width="266" class="ma-auto" src="/images/logo-vertical.svg" />
                </div>
                <v-divider />
                <div class="pa-30 py-md-20">
                    <v-row align="center">
                        <v-col cols="12" sm="6">
                            <v-img contain max-width="296" class="ma-auto" src="/images/logo-horizontal.svg" />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-img contain max-width="172" class="ma-auto" src="/images/logo.svg" />
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </page-section>

        <page-section>
            <div class="tit-wrap tit-wrap--dot">
                <h2 class="tit">COLOR OPTION</h2>
            </div>
            <v-row>
                <v-col cols="12" sm="6" md="4">
                    <v-card tile elevation="0" color="#01aee9" :height="$vuetify.breakpoint.xs ? '' : 160" class="d-flex flex-wrap align-center justify-center">
                        <div class="w-100 pa-30 py-md-24">
                            <v-img contain max-width="166" class="ma-auto" src="/images/logo-vertical-white.svg" />
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-card tile elevation="0" color="#01aee9" :height="$vuetify.breakpoint.xs ? '' : 160" class="d-flex flex-wrap align-center justify-center">
                        <div class="w-100 pa-30 py-md-24">
                            <v-img contain max-width="208" class="ma-auto" src="/images/logo-white.svg" />
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                    <v-card tile elevation="0" color="#01aee9" :height="$vuetify.breakpoint.xs ? '' : 160" class="d-flex flex-wrap align-center justify-center">
                        <div class="w-100 pa-30 py-md-24">
                            <v-img contain max-width="280" class="ma-auto" src="/images/logo-horizontal-white.svg" />
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </page-section>

        <page-section class="page-section--small">
            <div class="tit-wrap tit-wrap--dot">
                <h2 class="tit">MONOCOLOR OPTION</h2>
            </div>
            <v-row>
                <v-col cols="12" sm="6" md="4">
                    <v-card tile outlined elevation="0" :height="$vuetify.breakpoint.xs ? '' : 160" class="d-flex flex-wrap align-center justify-center">
                        <div class="w-100 pa-30 py-md-24">
                            <v-img contain max-width="166" class="ma-auto" src="/images/logo-vertical-dark.svg" />
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-card tile outlined elevation="0" :height="$vuetify.breakpoint.xs ? '' : 160" class="d-flex flex-wrap align-center justify-center">
                        <div class="w-100 pa-30 py-md-24">
                            <v-img contain max-width="208" class="ma-auto" src="/images/logo-dark.svg" />
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                    <v-card tile outlined elevation="0" :height="$vuetify.breakpoint.xs ? '' : 160" class="d-flex flex-wrap align-center justify-center">
                        <div class="w-100 pa-30 py-md-24">
                            <v-img contain max-width="280" class="ma-auto" src="/images/logo-horizontal-dark.svg" />
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </page-section>

        <page-section class="page-section--last">
            <div class="tit-wrap tit-wrap--dot">
                <h2 class="tit">센터의 캐릭터</h2>
            </div>
            <v-card tile outlined elevation="0" :height="$vuetify.breakpoint.xs ? '' : 260" class="d-flex flex-wrap align-center justify-center">
                <div class="w-100 pa-30 py-md-24 py-lg-60">
                    <v-row align="center" justify="center" class="ma-n20 ma-md-n40 ma-lg-n90">
                        <v-col cols="auto" class="pa-20 pa-md-40 pa-lg-90">
                            <v-img contain max-width="162" class="ma-auto" src="/images/character.svg" />
                        </v-col>
                        <v-col cols="auto" class="pa-20 pa-md-40 pa-lg-90">
                            <v-img contain max-width="346" class="ma-auto" src="/images/character-horizontal.svg" />
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <v-card tile elevation="0" color="#01aee9" :height="$vuetify.breakpoint.xs ? '' : 260" class="d-flex flex-wrap align-center justify-center">
                <div class="w-100 pa-30 py-md-24 py-lg-60">
                    <v-row align="center" justify="center" class="ma-n20 ma-md-n40 ma-lg-n90">
                        <v-col cols="auto" class="pa-20 pa-md-40 pa-lg-90">
                            <v-img contain max-width="162" class="ma-auto" src="/images/character-white.svg" />
                        </v-col>
                        <v-col cols="auto" class="pa-20 pa-md-40 pa-lg-90">
                            <v-img contain max-width="346" class="ma-auto" src="/images/character-horizontal-white.svg" />
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import ClientGnb from "@/components/client/inc/client-gnb.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/dumb/page-section.vue";

export default {
    components: {
        ClientPage,
        ClientGnb,
        SubVisual,
        PageSection,
    },
    mounted() {
    },
    methods: {
    },
};
</script>

<style scoped>
</style>