<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="센터소개" src="/images/sub/sv-intro.jpg"></sub-visual>
        </template>
        <template v-slot:contentsImmersive>
            <client-gnb className="sub-tab-wrap" tabTitle="센터소개" tabActive="센터소개"></client-gnb>
        </template>

        <page-section class="page-section--first">
            <v-row>
                <v-col cols="12" sm="6">
                    <div class="img-wrap img-wrap--left-bg">
                        <v-img max-width="540" src="/images/sub/intro/center-img.png" />
                    </div>
                </v-col>
                <v-spacer />
                <v-col cols="12" sm="6" md="5">
                    <div class="mb-12 mt-md-20">
                        <v-img max-width="50" src="/images/character.svg" />
                    </div>
                    <h2 class="tit tit--lg">하남시 <br class="d-none d-md-block" />도시재생지원센터</h2>
                    <v-divider class="my-20 my-md-30" />
                    <p class="page-text grey-1e--text">도시재생현장지원센터는 사업지 내 도시재생사업의 성공적인 <br class="d-none d-md-block" />추진과 확산을 위한 중간지원조직으로서 행정 - 전문가 - 주민 등 <br class="d-none d-md-block" />참여주체 간의 역할과 상호 연계하고, 조정하는 역할을 합니다.</p>
                </v-col>
            </v-row>
        </page-section>

        <page-section>
            <div class="tit-wrap tit-wrap--dot">
                <h2 class="tit">센터의 구성</h2>
            </div>
            <v-row>
                <v-col cols="12" sm="6" md="4">
                    <v-img contain max-height="132" src="/images/sub/intro/center-img2.png" />
                    <v-divider class="border-2 primary" />
                    <v-card :min-height="$vuetify.breakpoint.xs ? '' : 288" rounded="t-0">
                        <div class="pa-30 px-lg-50 py-lg-60">
                            <v-img contain position="left center" max-height="40" src="/images/sub/intro/center-text.png" alt="기초센터 잇다" />
                            <p class="page-text mt-14 mt-md-24">
                                도시재생사업 전반의 컨트롤타워 역할<br />
                                도시재생리더 육성<br />
                                민간 거버넌스 구축
                            </p>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-img contain max-height="132" src="/images/sub/intro/center-img3.png" />
                    <v-divider class="border-2 primary" />
                    <v-card :min-height="$vuetify.breakpoint.xs ? '' : 288" rounded="t-0">
                        <div class="pa-30 px-lg-50 py-lg-60">
                            <v-img contain position="left center" max-height="40" src="/images/sub/intro/center-text2.png" alt="현장지원센터 날다" />
                            <p class="page-text mt-14 mt-md-24">
                                주민 활동 지원<br />
                                주민 역량 강화<br />
                                신장동 네트워킹 활성화
                            </p>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-img contain max-height="132" src="/images/sub/intro/center-img4.png" />
                    <v-divider class="border-2 primary" />
                    <v-card :min-height="$vuetify.breakpoint.xs ? '' : 288" rounded="t-0">
                        <div class="pa-30 px-lg-50 py-lg-60">
                            <v-img contain position="left center" max-height="40" src="/images/sub/intro/center-text3.png" alt="생활문화센터 하다" />
                            <p class="page-text mt-14 mt-md-24">
                                주민의 문화감수성 향상<br />
                                주민의 문화적 역량 증진<br />
                                동아리 활동 지원<br />
                                지역사회 네트워킹 역할
                            </p>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </page-section>

        <page-section>
            <div class="tit-wrap tit-wrap--dot">
                <h2 class="tit">센터의 역할</h2>
            </div>
            <v-row>
                <v-col cols="12" md="6">
                    <div>
                        <v-row align="center" class="row--small">
                            <v-col cols="auto">
                                <v-responsive :width="$vuetify.breakpoint.xs ? 26 : 32" :aspect-ratio="1 / 1">
                                    <v-sheet width="100%" height="100%" rounded="circle" color="primary" class="d-flex align-center justify-center"><strong class="font-size-16 font-size-md-20 white--text">1</strong></v-sheet>
                                </v-responsive>
                            </v-col>
                            <v-col cols="">
                                <p class="page-text grey-1e--text">도시재생 전략계획, <b>도시재생 활성화 계획 수립 지원</b></p>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="mt-2 mt-md-8">
                        <v-row align="center" class="row--small">
                            <v-col cols="auto">
                                <v-responsive :width="$vuetify.breakpoint.xs ? 26 : 32" :aspect-ratio="1 / 1">
                                    <v-sheet width="100%" height="100%" rounded="circle" color="primary" class="d-flex align-center justify-center"><strong class="font-size-16 font-size-md-20 white--text">2</strong></v-sheet>
                                </v-responsive>
                            </v-col>
                            <v-col cols="">
                                <p class="page-text grey-1e--text">도시재생사업 <b>발굴 및 활성화 지원</b></p>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="mt-2 mt-md-8">
                        <v-row align="center" class="row--small">
                            <v-col cols="auto">
                                <v-responsive :width="$vuetify.breakpoint.xs ? 26 : 32" :aspect-ratio="1 / 1">
                                    <v-sheet width="100%" height="100%" rounded="circle" color="primary" class="d-flex align-center justify-center"><strong class="font-size-16 font-size-md-20 white--text">3</strong></v-sheet>
                                </v-responsive>
                            </v-col>
                            <v-col cols="">
                                <p class="page-text grey-1e--text">주민중심의 <b>민관 협력추진체계 구축</b></p>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="mt-2 mt-md-8">
                        <v-row align="center" class="row--small">
                            <v-col cols="auto">
                                <v-responsive :width="$vuetify.breakpoint.xs ? 26 : 32" :aspect-ratio="1 / 1">
                                    <v-sheet width="100%" height="100%" rounded="circle" color="primary" class="d-flex align-center justify-center"><strong class="font-size-16 font-size-md-20 white--text">4</strong></v-sheet>
                                </v-responsive>
                            </v-col>
                            <v-col cols="">
                                <p class="page-text grey-1e--text">문화적 재생, 사회적 경제 등 <b>연계사업 추진</b></p>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <v-img max-width="568" src="/images/sub/intro/center-img5.png" />
                </v-col>
            </v-row>
        </page-section>

        <page-section class="page-section--small page-section--last">
            <v-row>
                <v-col cols="6" sm="4" md="3" lg="2">
                    <v-responsive :width="$vuetify.breakpoint.xs ? 100 : 120" :aspect-ratio="1 / 1" class="ma-auto">
                        <v-sheet width="100%" height="100%" rounded="circle" color="primary" class="d-flex align-center justify-center">
                            <v-img contain max-height="48" class="ml-4" src="/images/sub/intro/center-icon.png" />
                        </v-sheet>
                    </v-responsive>
                    <div class="mt-8 mt-md-14 text-center">
                        <div class="tit-wrap mb-6 mb-md-8"><h3 class="tit tit--sm">조사</h3></div>
                        <p class="page-text page-text--sm">도시재생사업 추진을 위한 <br class="d-none d-md-block" />자원조사 및 주민의견 수렴</p>
                    </div>
                </v-col>
                <v-col cols="6" sm="4" md="3" lg="2">
                    <v-responsive :width="$vuetify.breakpoint.xs ? 100 : 120" :aspect-ratio="1 / 1" class="ma-auto">
                        <v-sheet width="100%" height="100%" rounded="circle" color="primary" class="d-flex align-center justify-center">
                            <v-img contain max-height="48" src="/images/sub/intro/center-icon2.png" />
                        </v-sheet>
                    </v-responsive>
                    <div class="mt-8 mt-md-14 text-center">
                        <div class="tit-wrap mb-6 mb-md-8"><h3 class="tit tit--sm">역량강화</h3></div>
                        <p class="page-text page-text--sm">주민역량강화를 위한 <br class="d-none d-md-block" />프로그램 운영</p>
                    </div>
                </v-col>
                <v-col cols="6" sm="4" md="3" lg="2">
                    <v-responsive :width="$vuetify.breakpoint.xs ? 100 : 120" :aspect-ratio="1 / 1" class="ma-auto">
                        <v-sheet width="100%" height="100%" rounded="circle" color="primary" class="d-flex align-center justify-center">
                            <v-img contain max-height="48" src="/images/sub/intro/center-icon3.png" />
                        </v-sheet>
                    </v-responsive>
                    <div class="mt-8 mt-md-14 text-center">
                        <div class="tit-wrap mb-6 mb-md-8"><h3 class="tit tit--sm">주민조직 구축</h3></div>
                        <p class="page-text page-text--sm">주민조직 구축을 <br class="d-none d-md-block" />위한 지원</p>
                    </div>
                </v-col>
                <v-col cols="6" sm="4" md="3" lg="2">
                    <v-responsive :width="$vuetify.breakpoint.xs ? 100 : 120" :aspect-ratio="1 / 1" class="ma-auto">
                        <v-sheet width="100%" height="100%" rounded="circle" color="primary" class="d-flex align-center justify-center">
                            <v-img contain max-height="48" src="/images/sub/intro/center-icon4.png" />
                        </v-sheet>
                    </v-responsive>
                    <div class="mt-8 mt-md-14 text-center">
                        <div class="tit-wrap mb-6 mb-md-8"><h3 class="tit tit--sm">홍보</h3></div>
                        <p class="page-text page-text--sm">도시재생사업 홍보 <br class="d-none d-md-block" />소식지 및 SNS 운영</p>
                    </div>
                </v-col>
                <v-col cols="6" sm="4" md="3" lg="2">
                    <v-responsive :width="$vuetify.breakpoint.xs ? 100 : 120" :aspect-ratio="1 / 1" class="ma-auto">
                        <v-sheet width="100%" height="100%" rounded="circle" color="primary" class="d-flex align-center justify-center">
                            <v-img contain max-height="48" src="/images/sub/intro/center-icon5.png" />
                        </v-sheet>
                    </v-responsive>
                    <div class="mt-8 mt-md-14 text-center">
                        <div class="tit-wrap mb-6 mb-md-8"><h3 class="tit tit--sm">거점공간</h3></div>
                        <p class="page-text page-text--sm">거점공간 운영 <br class="d-none d-md-block" />및 지원</p>
                    </div>
                </v-col>
                <v-col cols="6" sm="4" md="3" lg="2">
                    <v-responsive :width="$vuetify.breakpoint.xs ? 100 : 120" :aspect-ratio="1 / 1" class="ma-auto">
                        <v-sheet width="100%" height="100%" rounded="circle" color="primary" class="d-flex align-center justify-center">
                            <v-img contain max-height="48" src="/images/sub/intro/center-icon6.png" />
                        </v-sheet>
                    </v-responsive>
                    <div class="mt-8 mt-md-14 text-center">
                        <div class="tit-wrap mb-6 mb-md-8"><h3 class="tit tit--sm">기획</h3></div>
                        <p class="page-text page-text--sm">소규모 프로젝트 <br class="d-none d-md-block" />기획 및 운영</p>
                    </div>
                </v-col>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import ClientGnb from "@/components/client/inc/client-gnb.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/dumb/page-section.vue";

export default {
    components: {
        ClientPage,
        ClientGnb,
        SubVisual,
        PageSection,
    },
    mounted() {},
    methods: {},
};
</script>

<style scoped></style>
