import { render, staticRenderFns } from "./WhatPage.vue?vue&type=template&id=04a0c44f&scoped=true"
import script from "./WhatPage.vue?vue&type=script&lang=js"
export * from "./WhatPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04a0c44f",
  null
  
)

export default component.exports