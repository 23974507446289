var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "센터소개",
            "src": "/images/sub/sv-intro.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "contentsImmersive",
      fn: function () {
        return [_c('client-gnb', {
          attrs: {
            "className": "sub-tab-wrap",
            "tabTitle": "센터소개",
            "tabActive": "오시는길"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--first"
  }, [_c('v-card', {
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1200 / 460
    }
  }, [_c('iframe', {
    staticStyle: {
      "border": "0"
    },
    attrs: {
      "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.6768857638326!2d127.21241415106691!3d37.53911333335647!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357cb229714db195%3A0xc3500220f40bf28b!2z6rK96riw64-EIO2VmOuCqOyLnCDrjIDssq3roZwgMTA!5e0!3m2!1sko!2skr!4v1665048932652!5m2!1sko!2skr",
      "width": "100%",
      "height": "100%",
      "allowfullscreen": "",
      "loading": "lazy",
      "referrerpolicy": "no-referrer-when-downgrade"
    }
  })]), _c('v-card', {
    staticClass: "mt-n10 mt-md-n12"
  }, [_c('div', {
    staticClass: "pa-30 pa-md-40 px-lg-60 py-lg-46"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "tit primary--text"
  }, [_vm._v("기초센터 잇다")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-row', {
    staticClass: "mx-n8 my-n4",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "px-8 py-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "page-text grey-1e--text"
  }, [_c('strong', {
    staticClass: "pr-8 pr-md-10"
  }, [_vm._v("도시전략과")]), _vm._v("경기도 하남시 대청로 10 (하남시청 별관 2층)")])]), _c('v-col', {
    staticClass: "px-8 py-4",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text grey-1e--text"
  }, [_c('strong', {
    staticClass: "pr-8 pr-md-10"
  }, [_vm._v("전화번호")]), _vm._v("031-790-6353")])]), _c('v-col', {
    staticClass: "d-none d-md-flex align-center px-8 py-4",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "h-10px",
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "px-8 py-4",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text grey-1e--text"
  }, [_c('strong', {
    staticClass: "pr-8 pr-md-10"
  }, [_vm._v("운영시간")]), _vm._v("월~금 09:00~18:00")])])], 1)], 1)], 1)], 1)])], 1)], 1), _c('page-section', {
    staticClass: "pt-40 pt-md-60"
  }, [_c('v-card', {
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1200 / 460
    }
  }, [_c('iframe', {
    staticStyle: {
      "border": "0"
    },
    attrs: {
      "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.731189963381!2d127.2085633510667!3d37.53783343342967!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357cb187ed2126d7%3A0xa0e5075a2e42f1e5!2z6rK96riw64-EIO2VmOuCqOyLnCDsi6DsnqUx66GcMjLrsojquLggMTUtMQ!5e0!3m2!1sko!2skr!4v1665049040274!5m2!1sko!2skr",
      "width": "100%",
      "height": "100%",
      "allowfullscreen": "",
      "loading": "lazy",
      "referrerpolicy": "no-referrer-when-downgrade"
    }
  })]), _c('v-card', {
    staticClass: "mt-n10 mt-md-n12"
  }, [_c('div', {
    staticClass: "pa-30 pa-md-40 px-lg-60 py-lg-46"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "tit primary--text"
  }, [_vm._v("현장지원센터 날다")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-row', {
    staticClass: "mx-n8 my-n4",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "px-8 py-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "page-text grey-1e--text"
  }, [_c('strong', {
    staticClass: "pr-8 pr-md-10"
  }, [_vm._v("신장동 도시재생현장지원센터")]), _vm._v("경기 하남시 신장1로22번길 15-1")])]), _c('v-col', {
    staticClass: "px-8 py-4",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text grey-1e--text"
  }, [_c('strong', {
    staticClass: "pr-8 pr-md-10"
  }, [_vm._v("전화번호")]), _vm._v("031-796-2176")])]), _c('v-col', {
    staticClass: "d-none d-md-flex align-center px-8 py-4",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "h-10px",
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "px-8 py-4",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text grey-1e--text"
  }, [_c('strong', {
    staticClass: "pr-8 pr-md-10"
  }, [_vm._v("운영시간")]), _vm._v("월~금 09:00~18:00")])])], 1)], 1)], 1)], 1)])], 1)], 1), _c('page-section', {
    staticClass: "pt-40 pt-md-60 page-section--last"
  }, [_c('v-card', {
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1200 / 460
    }
  }, [_c('iframe', {
    staticStyle: {
      "border": "0"
    },
    attrs: {
      "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.6527137563817!2d127.21176325106693!3d37.53968303332381!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357cb22983666189%3A0x76a32837b057ae95!2z6rK96riw64-EIO2VmOuCqOyLnCDsi6DsnqXrj5kgNTIwLTE!5e0!3m2!1sko!2skr!4v1665049198217!5m2!1sko!2skr",
      "width": "100%",
      "height": "100%",
      "allowfullscreen": "",
      "loading": "lazy",
      "referrerpolicy": "no-referrer-when-downgrade"
    }
  })]), _c('v-card', {
    staticClass: "mt-n10 mt-md-n12"
  }, [_c('div', {
    staticClass: "pa-30 pa-md-40 px-lg-60 py-lg-46"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "tit primary--text"
  }, [_vm._v("생활문화센터 하다")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-row', {
    staticClass: "mx-n8 my-n4",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "px-8 py-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "page-text grey-1e--text"
  }, [_c('strong', {
    staticClass: "pr-8 pr-md-10"
  }, [_vm._v("생활문화센터")]), _vm._v("경기도 하남시 대청로 8 (시청옆 경관광장)")])]), _c('v-col', {
    staticClass: "px-8 py-4",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text grey-1e--text"
  }, [_c('strong', {
    staticClass: "pr-8 pr-md-10"
  }, [_vm._v("전화번호")]), _vm._v("031-791-5002")])]), _c('v-col', {
    staticClass: "d-none d-md-flex align-center px-8 py-4",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "h-10px",
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "px-8 py-4",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text grey-1e--text"
  }, [_c('strong', {
    staticClass: "pr-8 pr-md-10"
  }, [_vm._v("운영시간")]), _vm._v("수~일 10:00~19:00")])])], 1)], 1)], 1)], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }