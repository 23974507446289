var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "하남시도시재생",
            "src": "/images/sub/sv-regeneration.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "contentsImmersive",
      fn: function () {
        return [_c('client-gnb', {
          attrs: {
            "className": "sub-tab-wrap",
            "tabTitle": "하남시도시재생",
            "tabActive": "사업구성"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('div', [_c('v-card', [_c('div', {
    staticClass: "pa-30 py-md-50 px-lg-50 px-xl-90"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-300px w-lg-440px mb-20 mb-md-0 pr-md-24"
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "max-height": "170",
      "position": _vm.$vuetify.breakpoint.xs ? 'center' : 'left center',
      "src": "/images/sub/regeneration/structure-img.png"
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "mb-16 mb-md-22"
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "position": "left center",
      "max-height": "40",
      "src": "/images/sub/regeneration/structure-text.png",
      "alt": "기초센터 잇다"
    }
  })], 1), _c('p', {
    staticClass: "page-text"
  }, [_vm._v("도시재생사업 전반의 컨트롤타워 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("역할수행과 도시재생리더 육성 및 민간 거버넌스 구축")])])], 1)], 1)])], 1), _c('div', {
    staticClass: "mt-28 mt-md-34"
  }, [_c('v-card', [_c('div', {
    staticClass: "pa-30 py-md-50 px-lg-50 px-xl-90"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-300px w-lg-440px mb-20 mb-md-0 pr-md-24"
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "max-height": "170",
      "position": _vm.$vuetify.breakpoint.xs ? 'center' : 'left center',
      "src": "/images/sub/regeneration/structure-img2.png"
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "mb-16 mb-md-22"
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "position": "left center",
      "max-height": "40",
      "src": "/images/sub/regeneration/structure-text2.png",
      "alt": "현장지원센터 날다"
    }
  })], 1), _c('p', {
    staticClass: "page-text"
  }, [_vm._v("주민공동체 발굴과 역량강화를 통한 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("도시재생 인식과 이해도를 높이고 주민 자립 지원")])])], 1)], 1)])], 1), _c('div', {
    staticClass: "mt-28 mt-md-34"
  }, [_c('v-card', [_c('div', {
    staticClass: "pa-30 py-md-50 px-lg-50 px-xl-90"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-300px w-lg-440px mb-20 mb-md-0 pr-md-24"
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "max-height": "170",
      "position": _vm.$vuetify.breakpoint.xs ? 'center' : 'left center',
      "src": "/images/sub/regeneration/structure-img3.png"
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "mb-16 mb-md-22"
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "position": "left center",
      "max-height": "40",
      "src": "/images/sub/regeneration/structure-text3.png",
      "alt": "생활문화센터 하다"
    }
  })], 1), _c('p', {
    staticClass: "page-text"
  }, [_vm._v("지역주민의 문화예술활동을 지역사회와 공유하며 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("생활문화를 만들어가는 커뮤니티 공간")])])], 1)], 1)])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }