var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "하남시도시재생",
            "src": "/images/sub/sv-regeneration.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "contentsImmersive",
      fn: function () {
        return [_c('client-gnb', {
          attrs: {
            "className": "sub-tab-wrap",
            "tabTitle": "하남시도시재생",
            "tabActive": "도시재생이란?"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--first"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('div', {
    staticClass: "img-wrap img-wrap--left-bg"
  }, [_c('v-img', {
    attrs: {
      "max-width": "436",
      "src": "/images/sub/regeneration/what-img.png"
    }
  })], 1)]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "mb-12 mt-md-20"
  }, [_c('v-img', {
    attrs: {
      "max-width": "50",
      "src": "/images/character.svg"
    }
  })], 1), _c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v("도시재생이란?")]), _c('v-divider', {
    staticClass: "my-20 my-md-30"
  }), _c('p', {
    staticClass: "page-text grey-1e--text"
  }, [_vm._v("인구 감소, 산업 쇠퇴, 주거환경 노후로 인해 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("쇠락하는 도시를 물리적 · 문화적 · 사회적 · 경제적으로 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("개선하여 도시의 기능을 회복하고 경쟁력 있는 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("정주환경으로 재창조하는 사업입니다.")])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--last"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--dot"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("도시재생사업에 참여하기")])]), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    attrs: {
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "pa-30 pa-md-40 py-lg-50"
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "max-height": "74",
      "src": "/images/sub/regeneration/what-icon.png"
    }
  }), _c('div', {
    staticClass: "mt-10 mt-md-18 text-center"
  }, [_c('div', {
    staticClass: "tit-wrap mb-6 mb-md-8"
  }, [_c('h3', {
    staticClass: "tit tit--sm primary--text"
  }, [_vm._v("교육 및 워크숍")])]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v("도시재생 마을학교 등 관심사에 따른 프로그램 참여")])])], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    attrs: {
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "pa-30 pa-md-40 py-lg-50"
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "max-height": "64",
      "src": "/images/sub/regeneration/what-icon2.png"
    }
  }), _c('div', {
    staticClass: "mt-10 mt-md-18 text-center"
  }, [_c('div', {
    staticClass: "tit-wrap mb-6 mb-md-8"
  }, [_c('h3', {
    staticClass: "tit tit--sm primary--text"
  }, [_vm._v("SNS 및 소식지")])]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v("SNS를 통한 도시재생 소식 공유")])])], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    attrs: {
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "pa-30 pa-md-40 py-lg-50"
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "max-height": "64",
      "src": "/images/sub/regeneration/what-icon3.png"
    }
  }), _c('div', {
    staticClass: "mt-10 mt-md-18 text-center"
  }, [_c('div', {
    staticClass: "tit-wrap mb-6 mb-md-8"
  }, [_c('h3', {
    staticClass: "tit tit--sm primary--text"
  }, [_vm._v("마을사업단")])]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v("도시재생 마중물사업 참여")])])], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    attrs: {
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "pa-30 pa-md-40 py-lg-50"
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "max-height": "64",
      "src": "/images/sub/regeneration/what-icon4.png"
    }
  }), _c('div', {
    staticClass: "mt-10 mt-md-18 text-center"
  }, [_c('div', {
    staticClass: "tit-wrap mb-6 mb-md-8"
  }, [_c('h3', {
    staticClass: "tit tit--sm primary--text"
  }, [_vm._v("주민협의체")])]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v("· 도시재생 주체로서 관련의견 제시"), _c('br'), _vm._v("· 지속가능한 마을공동체활동 참여")])])], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    attrs: {
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "pa-30 pa-md-40 py-lg-50"
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "max-height": "64",
      "src": "/images/sub/regeneration/what-icon5.png"
    }
  }), _c('div', {
    staticClass: "mt-10 mt-md-18 text-center"
  }, [_c('div', {
    staticClass: "tit-wrap mb-6 mb-md-8"
  }, [_c('h3', {
    staticClass: "tit tit--sm primary--text"
  }, [_vm._v("주민공모사업")])]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 도시재생과 지역특성에 맞는 사업을 직접 기획·실행")])])], 1)])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }